<template>
  <div></div>
</template>
<script>
export default{
    data(){
        return{
            url:'http://piao.bjry.com/'
        }
    },
    created(){
        this.$router.go(-1)
        window.open(this.url)
    },
}
</script>
<style>
</style>